import { defineStore} from "pinia";
import {Printer} from '../other/DataStructs'
import { useTicketsStore } from "./tickets";
import { ref} from "vue";

export const usePrinterStore = defineStore("printerStore", {
    state: () =>{
        return {
            //printers
            printers: ref(
                [
                    new Printer("Raise3d Rai e3d Pro2", "/printers/raise.jpg", 1, {x:305, y:305, z:300}),
                    new Printer("Ultimaker 3 extended", "/printers/ultimaker.jpg", 2, {x:342, y:380, z:389}),
                    new Printer("Anycubic photon", "/printers/anycubic.jpg", 3, {x:115, y:65, z:155}),
                    new Printer("Voron v2.4 R2", "/printers/voron.jpg", 4, {x:350, y:350, z:350}),
                    new Printer("???", "/printers/mystery.png", 5, {x:100, y:100, z:100}),
                ] as Printer[]
            ),
            editPrinter: new Printer("", "", 0, {x:0, y:0, z:0}),
            editTicketQueue: [] as any[]
        };
        
    },
    actions: {
        /**
         * Loads in the tickets, which are in the ticketQueue from its id.
         * @param printer the specific printer from which the ticketqueue should be filled.
         */
        setEditTicketQueue(printer: Printer){
            const ticketStore = useTicketsStore();
            this.editTicketQueue.length = 0;

            for (let i = 0; i < printer.ticketQueue.length; i++) {
                const id = printer.ticketQueue[i];
                this.editTicketQueue.push(ticketStore.getTicket(id));
            }
        }
    },

});