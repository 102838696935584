import { defineStore} from "pinia";
import axios from "axios";
import { useTicketsStore } from "./tickets";

export const useStatsStore = defineStore("statsStore", {
    state: () =>{
        return {
            
        };
    },
    actions: {
        /**
         * Sends a request to the server to get the colors, which are used in the tickets currently.
         * @returns an array of the quantity of colors which are used. (Index corresponds to the index in printingMaterials)
         */
        async getTicketColors(){
            const ticketStore = useTicketsStore();

            const varname = "color_id";
            const responseObject = await axios.get(`${process.env.VUE_APP_API_URL}/database/stats/${varname}/statistics`)
            const colorsUsed = responseObject.data.color_id;

            const distributionColors:number[] = [ticketStore.colors.length];
            for (let i = 0; i < ticketStore.colors.length; i++) {
                distributionColors[i] = 0;
            }

            for(const usedColor of colorsUsed){
                for (let i = 0; i < ticketStore.colors.length; i++) {
                    const color = ticketStore.colors[i];
                    if(usedColor == color.id){
                        distributionColors[i] += 1;
                    }
                }
            }
            return distributionColors;
        }
    },

});