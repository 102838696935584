import { v4 as uuidv4 } from 'uuid';
import { useAuthStore } from '@/stores/authStore';



/**
 * Stores information about a printing ticket.
 */
export class PrintingTicket{

    //unique id
    id: string;

    /**client who send the printing request*/
    client: string = "";
    /**assignee who is responsible for printing the ticket */
    assignee: string = "";
    /**printer*/
    printer: string = "";

    /**model name**/
    ticket_name: string = ""

    fileImageAddress: string = ""

    model_filepath: string = ""

    /**date on which the ticket should be finished*/
    deliverydate: string = "";
    priority: number = 0;

    /**1: not assigned | 2: in progress | 3: printed*/
    status: number = 0;

    color_id: number = 0;

    /**creates a new ticket */
    constructor(client: string, assignee: string, printer: string, ticket_name: string, priority: number, deliverydate: string, color_id: number, fileImageAdress: string, model_filepath: string){
        this.id = uuidv4();

        this.setClient(client);
        this.assignee = assignee;
        this.printer = printer;
        this.setTicket_name(ticket_name);
        this.setPriority(priority);
        this.setDeliveryDate(deliverydate);
        this.setColor_id(color_id);
        this.setFileImage(fileImageAdress);
        this.setFileAdress(model_filepath);
    }

    setClient(client: string){
        this.client = client;
    }

    setTicket_name(ticket_name: string){
        this.ticket_name = ticket_name;
    }

    setPriority(priority: number){
        this.priority = priority;
    }
    
    setDeliveryDate(deliverydate: string){
        this.deliverydate = deliverydate;
    }
    setColor_id(color_id: number){
        this.color_id = color_id;
    }


    setStatus(status: number){
        this.status = status;
    }

    setFileImage(fileImageAddress: string){
        this.fileImageAddress = fileImageAddress;
    }

    setFileAdress(model_filepath: string){
        this.model_filepath = model_filepath;
    }
}

export class user{

    authStore = useAuthStore();

    private name: string = "";

    private role: string = "";

    constructor(name: string, role: string){
        this.setName(name);
        this.setRole(role);
    }

    public setName(name:string){
        this.name = name;
    }
    public setRole(role:string){
        if(this.authStore.userRoles.includes(role)){
            this.role = role;
        }
        else{
            throw "not a valid role name!";
        }
    }
}

export class Printer{

    id = "";
    fileImageAddress = "";
    name = "";
    /**stores ids from the tickets, which should be printed with this printer. */
    ticketQueue = [] as string[];
    /**color currently in use for printing. */
    colorInUse: number = 0;
    /**dimensions in mm */
    print_dimensions = {x:0,y:0,z:0} as object;

    constructor(name:string, fileImageAddress: string, colorInUse: number, print_dimensions:object){
        this.id = uuidv4();
        this.name = name;
        this.fileImageAddress = fileImageAddress;
        this.colorInUse = colorInUse;
        this.print_dimensions = print_dimensions;
    }
}